import React, { useMemo, useState } from "react"

import algoliasearch from "algoliasearch"
import { Configure, InstantSearch } from "react-instantsearch-dom"
import { useIntl } from "react-intl"

import CustomSearchBox from "../CustomSearchBox/CustomSearchBox"
import {
  CustomSelectionHits,
  PartialHit,
  PartialHitTranslation,
} from "../CustomSelectionHits/CustomSelectionHits"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./AutocompleteSearch.module.scss"

const GATSBY_ALGOLIA_API_KEY = process.env.GATSBY_ALGOLIA_API_KEY ?? ""
const GATSBY_ALGOLIA_APPLICATION_ID =
  process.env.GATSBY_ALGOLIA_APPLICATION_ID ?? ""
interface PropTypes {
  setSelection: (hit: PartialHit | PartialHitTranslation | undefined) => void
  selection?: PartialHit
  onBlur: () => void
  index: string
  placeholder: string
  limit?: number
}
const AutocompleteSearch = ({
  setSelection,
  selection,
  onBlur,
  index,
  placeholder,
  limit = 7,
  ...rest
}: PropTypes) => {
  const searchClientSelections = useMemo(
    () => algoliasearch(GATSBY_ALGOLIA_APPLICATION_ID, GATSBY_ALGOLIA_API_KEY),
    []
  )

  const [selectionSearchBoxFocus, setSelectionSearchBoxFocus] = useState(false)
  const intl = useIntl()

  return (
    <div className={css.root}>
      <InstantSearch indexName={index} searchClient={searchClientSelections}>
        <Configure hitsPerPage={limit} filters={`language:${intl.locale}`} />
        <CustomSearchBox
          setOnFocus={setSelectionSearchBoxFocus}
          isFocus={selectionSearchBoxFocus}
          initialValue={selection?.name}
          clearSelect={onBlur}
          placeholder={placeholder}
        />

        {selectionSearchBoxFocus && (
          <div className={css.suggestions}>
            <CustomSelectionHits setSelection={setSelection} />
          </div>
        )}
      </InstantSearch>
    </div>
  )
}

export default AutocompleteSearch
