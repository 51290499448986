import { graphql, useStaticQuery } from "gatsby"

import { Category } from "~/models/Category"

export interface AllCategories {
  [key: string]: Category
}

export interface inputCategory {
  name: string
  parentId: string
  categoryId: string
  language: string
}

function useCategories(intlLocal: string): AllCategories {
  const categoriesQuery = useStaticQuery(graphql`
    {
      allCategory {
        nodes {
          id
          name
          slug
          title
          language
          categoryId
          description
          parentId
          parentSlug
        }
      }
    }
  `)

  const categoriesFilteredByLanguage = categoriesQuery.allCategory.nodes.filter(
    (category: any) => category.language === intlLocal
  )

  //convert Array to AllCategories
  const result: AllCategories = {}
  for (const category of categoriesFilteredByLanguage) {
    result[category.categoryId] = {
      ...category,
      id: category.categoryId,
    } as Category
  }

  return result
}

export default useCategories
