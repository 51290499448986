import React, { useCallback, useState } from "react"

import throttle from "lodash/throttle"
import { useIntl } from "react-intl"

import AvatarName from "./AvatarName/AvatarName"
import { PopUpContext } from "./PopUpContext"
import RecipeAutosavePopUp from "./RecipeAutosavePopUp/RecipeAutosavePopUp"
import { RecipeContext } from "./RecipeContext"
import RecipeCreationIngredientsIndication from "./RecipeCreationIngredientsIndication/RecipeCreationIngredientsIndication"
import RecipeDelete from "./RecipeDelete/RecipeDelete"
import RecipeStatus from "./RecipeStatus/RecipeStatus"
import RecipeUpdateCategory from "./RecipeUpdateCategory/RecipeUpdateCategory"
import { RecipeUpdateDiets } from "./RecipeUpdateDiets/RecipeUpdateDiets"
import RecipeUpdateKitchenwares from "./RecipeUpdateKitchenwares/RecipeUpdateKitchenwares"
import RecipeUpdateOrigins from "./RecipeUpdateOrigins/RecipeUpdateOrigins"
import { RecipeUpdatePersonsNumber } from "./RecipeUpdatePersonsNumber/RecipeUpdatePersonsNumber"
import { RecipeUpdatePicture } from "./RecipeUpdatePicture/RecipeUpdatePicture"
import { RecipeUpdatePrepInfo } from "./RecipeUpdatePrepInfo/RecipeUpdatePrepInfo"
import { RecipeUpdateSteps } from "./RecipeUpdateSteps/RecipeUpdateSteps"
import { RecipeUpdateTextAreaField } from "./RecipeUpdateTextAreaField/RecipeUpdateTextAreaField"
import { RecipeUpdateTextField } from "./RecipeUpdateTextField/RecipeUpdateTextField"
import SubmitRecipe from "./SubmitRecipe/SubmitRecipe"
import TogglePublicRecipe from "./TogglePublicRecipe/TogglePublicRecipe"
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb"
import Separator from "~/components/Separator/Separator"
import { Col, Container, Row } from "~/components/ui"
import { ApiCommunityRecipe } from "~/models/CommunityRecipe"
import { User } from "~/models/User"

import css from "./RecipeCreation.module.scss"

export interface popUpMessage {
  type: "saved" | "error"
  message: string | null
}

interface PropTypes {
  recipe: ApiCommunityRecipe
  user: User
  refetchRecipe: () => void
}

const POP_UP_OPEN_TIME = 3000
const THROTTLE_TIME = 30000

const RecipeCreation = ({ recipe, user, refetchRecipe }: PropTypes) => {
  const intl = useIntl()
  const [popUp, setPopUp] = useState<popUpMessage | null>(null)

  const openPopUp = (state: popUpMessage) => {
    setPopUp(state)
    setTimeout(() => {
      setPopUp(null)
    }, POP_UP_OPEN_TIME)
  }

  const throttledSavedPopUp = useCallback(
    throttle(state => {
      openPopUp(state)
    }, THROTTLE_TIME),
    []
  )

  const setPopUpContent = (state: popUpMessage) => {
    if (state.type === "error") {
      openPopUp(state)
    } else {
      throttledSavedPopUp(state)
    }
  }

  return (
    <div className={css.root}>
      <PopUpContext.Provider
        value={{ content: popUp, setContent: setPopUpContent }}
      >
        <RecipeContext.Provider value={{ recipe: recipe, refetchRecipe }}>
          <div className={css.breadcrumbContainer}>
            <Breadcrumb
              pageTitle={intl.formatMessage({
                id: "recipe-creation/text:breadcrumb",
              })}
              template={"Profile"}
            />
          </div>
          <Container>
            <Row>
              <Col width={[12, 8]}>
                <RecipeAutosavePopUp />
                <div className={css.statusContainer}>
                  <RecipeStatus />
                </div>
                <Separator noMargin />
                <div className={css.titleContainer}>
                  <RecipeUpdateTextField
                    initialValue={{ title: recipe?.title ?? "" }}
                    apiKey="title"
                    placeholder={intl.formatMessage({
                      id: "recipe-creation/text:title-recipe-input-placeholder",
                    })}
                    variant="extraLarge"
                    recipeUuid={recipe.uuid}
                  />
                </div>
                <div className={css.dietsContainer}>
                  <RecipeUpdateDiets />
                </div>
                <div className={css.originsContainer}>
                  <RecipeUpdateOrigins uuid={recipe.uuid} />
                </div>
                <div className={css.categoryContainer}>
                  <RecipeUpdateCategory />
                </div>
                <div className={css.pictureContainer}>
                  <RecipeUpdatePicture />
                </div>
                <div className={css.avatarNameContainer}>
                  <AvatarName user={user} />
                </div>
                <RecipeUpdateTextAreaField
                  initialValue={{ description: recipe?.description ?? "" }}
                  apiKey="description"
                  placeholder={intl.formatMessage({
                    id: "recipe-creation/text:description-recipe-input-placeholder",
                  })}
                  recipeUuid={recipe.uuid}
                  required={false}
                />
                <div className={css.prepInfoContainer}>
                  <RecipeUpdatePrepInfo
                    totalMinutes={recipe.duration_minutes}
                    preparationMinutes={recipe.preparation_minutes}
                    cookingMinutes={recipe.cooking_minutes}
                    restingMinutes={recipe.resting_minutes}
                  />
                </div>
                <RecipeUpdatePersonsNumber persons={recipe.number_of_people} />
                <RecipeCreationIngredientsIndication />
                <div className={css.separator}>
                  <Separator />
                </div>
                <RecipeUpdateSteps />
                <div className={css.separator}>
                  <Separator />
                </div>
                <RecipeUpdateKitchenwares />
                <div className={css.separator}>
                  <Separator />
                </div>
                <div className={css.tooglePublicContainer}>
                  <TogglePublicRecipe />
                </div>
                {!recipe.is_draft && (
                  <div className={css.submitContainer}>
                    <SubmitRecipe />
                  </div>
                )}
                <div className={css.deleteContainer}>
                  <RecipeDelete uuid={recipe.uuid} />
                </div>
              </Col>
            </Row>
          </Container>
        </RecipeContext.Provider>
      </PopUpContext.Provider>
    </div>
  )
}

export default RecipeCreation
