import React, { useCallback, useContext, useEffect, useState } from "react"

import debounce from "lodash/debounce"
import { useIntl } from "react-intl"

import useAutosavePopUp from "../hooks/useAutosavePopUp"
import { RecipeContext } from "../RecipeContext"
import minusIcon from "./assets/minus.svg"
import plusIcon from "./assets/plus.svg"
import { Body, Heading } from "~/components/ui"
import { useUpdateUserRecipeMutation } from "~/state/api/communityRecipes"

import css from "./RecipeUpdatePersonsNumber.module.scss"

type RecipeUpdatePersonsNumberProps = {
  persons: number
}

const DEBOUNCE_TIME = 2000

export function RecipeUpdatePersonsNumber({
  persons,
}: RecipeUpdatePersonsNumberProps) {
  const intl = useIntl()
  const recipeContext = useContext(RecipeContext)
  const [personsNumber, setPersonsNumber] = useState(persons)

  const [updateData, { isLoading, isSuccess, isError, error }] =
    useUpdateUserRecipeMutation()

  useAutosavePopUp({
    isSuccess,
    isLoading,
    isError,
    error,
  })

  const updateNumPerson = useCallback(
    debounce(personsNumber => {
      recipeContext &&
        updateData({
          uuid: recipeContext?.recipe.uuid,
          body: {
            number_of_people: personsNumber,
          },
        })
    }, DEBOUNCE_TIME),
    []
  )

  useEffect(() => {
    if (personsNumber !== persons) {
      updateNumPerson(personsNumber)
    }
  }, [personsNumber, updateNumPerson])

  return (
    <div className={css.root}>
      <Heading variant="medium">
        {intl.formatMessage({
          id: "recipe-creation/text:people-number",
        })}
      </Heading>
      <div className={css.editSection}>
        <img
          onClick={() => {
            personsNumber > 0 && setPersonsNumber(personsNumber - 1)
          }}
          loading="lazy"
          src={minusIcon}
          alt=""
        />
        <Body className={css.number} variant="body1" bold>
          {personsNumber}
        </Body>
        <img
          onClick={() => {
            setPersonsNumber(personsNumber + 1)
          }}
          loading="lazy"
          src={plusIcon}
          alt=""
        />
      </div>
      {isError && error && "message" in error && (
        <div className={css.error}>
          <Body variant="body1" color="original">
            {error.message}
          </Body>
        </div>
      )}
    </div>
  )
}
