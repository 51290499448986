import React, { useContext, useEffect, useState } from "react"

import { useIntl } from "react-intl"

import AutocompleteSearch from "../Algolia/AutocompleteSearch/AutocompleteSearch"
import {
  PartialHit,
  PartialHitTranslation,
} from "../Algolia/CustomSelectionHits/CustomSelectionHits"
import useAutosavePopUp from "../hooks/useAutosavePopUp"
import { RecipeContext } from "../RecipeContext"
import { Body, Heading } from "~/components/ui"
import { useUpdateUserRecipeMutation } from "~/state/api/communityRecipes"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./RecipeUpdateOrigins.module.scss"

const GATSBY_ALGOLIA_ORIGINS_INDEX =
  process.env.GATSBY_ALGOLIA_ORIGINS_INDEX ?? ""

export type OriginsAlgolia = {
  objectID: string
  uuid: string
  name: string
  language: string
  region_of_origin: {
    uuid: string
    slug: string
  }
}

interface PropTypes {
  uuid: string
}

const RecipeUpdateOrigins = ({ uuid }: PropTypes) => {
  const intl = useIntl()
  const recipeContext = useContext(RecipeContext)
  const initialPartialHit = {
    objectID: "",
    name: recipeContext?.recipe.region_of_origin?.translation.name ?? "",
    uuid: "",
    language: "",
    region_of_origin: {
      uuid: "",
      slug: "",
    },
  }
  const [selectedOrigin, setSelectedOrigin] = useState<
    PartialHitTranslation | PartialHit | undefined
  >(initialPartialHit)

  const [updateData, { isLoading, isError, error, isSuccess, data }] =
    useUpdateUserRecipeMutation()

  useAutosavePopUp({ isSuccess, isLoading, isError, error })

  useEffect(() => {
    if (
      selectedOrigin &&
      "region_of_origin" in selectedOrigin &&
      selectedOrigin.region_of_origin.uuid &&
      !isLoading
    ) {
      updateData({
        uuid,
        body: {
          region_of_origin: {
            uuid: selectedOrigin.region_of_origin.uuid,
          },
        },
      })
    }
  }, [selectedOrigin])

  const onBlur = () => {
    //API CALL TO REMOVE ORIGIN
    setSelectedOrigin(initialPartialHit)
  }

  return (
    <div className={css.root}>
      <div className={css.row}>
        <Heading variant="medium">
          {intl.formatMessage({
            id: "recipe-creation/text:origin-heading",
          })}
        </Heading>
        <Body variant="body2" color="gray2">
          {intl.formatMessage({
            id: "recipe-creation/text:optional-field",
          })}
        </Body>
      </div>
      <AutocompleteSearch
        index={GATSBY_ALGOLIA_ORIGINS_INDEX}
        setSelection={setSelectedOrigin}
        selection={selectedOrigin}
        onBlur={() => onBlur()}
        placeholder={intl.formatMessage({
          id: "recipe-creation/text:origin-pleaceholder",
        })}
        limit={13}
      />
    </div>
  )
}

export default RecipeUpdateOrigins
