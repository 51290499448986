import React, { useContext, useEffect, useState } from "react"

import cn from "classnames"
import { useIntl } from "react-intl"

import binIcon from "../assets/bin.svg"
import penIcon from "../assets/pen.svg"
import useAutosavePopUp from "../hooks/useAutosavePopUp"
import { PopUpContext } from "../PopUpContext"
import { RecipeContext } from "../RecipeContext"
import NewStepModal from "./NewStepModal/NewStepModal"
import { Body, Button, Heading, ImageCard } from "~/components/ui"
import { useDeleteRecipeStepsMutation } from "~/state/api/communityRecipe/communityRecipeSteps"

import css from "./RecipeUpdateSteps.module.scss"

export function RecipeUpdateSteps() {
  const intl = useIntl()
  const popUp = useContext(PopUpContext)
  const recipeContext = useContext(RecipeContext)

  const steps = recipeContext?.recipe.steps_details ?? []
  const recipe = recipeContext?.recipe

  const [indexModal, setIndexModal] = useState<number | undefined>(undefined)
  const [openModal, setOpenModal] = useState(false)

  const [
    deleteRecipeSteps,
    {
      isLoading: deleteIsLoading,
      isError: deleteIsError,
      error: deleteError,
      isSuccess: deleteIsSuccess,
    },
  ] = useDeleteRecipeStepsMutation()

  useAutosavePopUp({
    isSuccess: deleteIsSuccess,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
    error: deleteError,
  })

  const handleDeleteStep = (uuid: string) => {
    deleteRecipeSteps({
      uuid: uuid,
    })
  }

  const handleOpenModal = (index: number) => {
    setIndexModal(index)
    setOpenModal(true)
  }

  useEffect(() => {
    recipeContext?.refetchRecipe()
  }, [openModal])

  return (
    <div className={css.root}>
      {recipe && (
        <NewStepModal
          etapeIndex={indexModal ?? 0}
          open={openModal}
          initialValue={
            indexModal || indexModal == 0 ? steps[indexModal] : undefined
          }
          onClose={() => {
            setOpenModal(false)
          }}
        />
      )}
      {!steps.length && (
        <div className={css.header}>
          <Heading variant="medium">
            {intl.formatMessage({
              id: "recipe-creation/text:steps-heading",
            })}
          </Heading>
        </div>
      )}

      {steps.map((step, index) => (
        <div className={css.step} key={index}>
          <div className={css.stepHeader}>
            <Heading variant="medium">
              {intl.formatMessage({
                id: "recipe-creation/text:step-heading",
              })}{" "}
              {index + 1}
            </Heading>
            <div className={css.icons}>
              <img
                loading="lazy"
                src={penIcon}
                alt=""
                onClick={() => handleOpenModal(index)}
              />
              <img
                loading="lazy"
                src={binIcon}
                alt=""
                onClick={() => handleDeleteStep(step.uuid)}
              />
            </div>
          </div>
          {step.image && (
            <div className={css.thumbnail}>
              <ImageCard
                imageProps={{
                  src: step.image,
                }}
              />
            </div>
          )}
          <Body variant="body1" semiBold key={index}>
            {step.ingredients.map(ingredient => ingredient.result).join(" - ")}
          </Body>

          <Body variant="body1" color="black" className={css.stepDescription}>
            {step.description}
          </Body>
        </div>
      ))}

      <Button
        variant="tertiary"
        uppercase={false}
        size="medium"
        color="original"
        className={cn({ [css.buttonWithSteps]: steps.length })}
        onClick={() => {
          setIndexModal(steps.length)
          setOpenModal(true)
        }}
      >
        {intl.formatMessage({
          id: "recipe-creation/action:new-step",
        })}
      </Button>
    </div>
  )
}
