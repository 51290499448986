import { CommunityFullIngredientRecipeContent } from "../SelectIngredients"
import { Ingredient } from "~/models/Recipe"
import { CommunityRecipeIngredientsArgs } from "~/state/api/communityRecipe/communityRecipeIngredients"

export const CommunityFullIngredientRecipeContentToAPI = (
  communityFullIngredientRecipeContent: CommunityFullIngredientRecipeContent
): CommunityRecipeIngredientsArgs => {
  const apiIngredient: CommunityRecipeIngredientsArgs = {
    ingredient: {
      uuid: communityFullIngredientRecipeContent.ingredient.uuid,
    },
    unit: {
      uuid: communityFullIngredientRecipeContent.unit.uuid,
    },
    quantity: communityFullIngredientRecipeContent.quantity,
    recipe_step: communityFullIngredientRecipeContent.recipe_step,
  }
  return apiIngredient
}

export const IngredientToCommunityFullIngredientRecipeContent = (
  ingredients: Ingredient[],
  recipe_step: string
): CommunityFullIngredientRecipeContent[] => {
  const fullIngredients: CommunityFullIngredientRecipeContent[] =
    ingredients.map((ingredient: Ingredient) => {
      return {
        ingredient: {
          uuid: ingredient.ingredient.uuid,
          name: ingredient.ingredient.name,
        },
        unit: {
          uuid: ingredient.unit.uuid,
          name: ingredient.unit.name,
        },
        quantity: ingredient.quantity,
        recipe_step: recipe_step,
        uuid: ingredient.uuid,
      }
    })
  return fullIngredients
}

export const PM_ID = "cc1d697a-a46e-4cfd-a687-be0f5e19961f"
