import React, { useContext, useEffect, useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import cn from "classnames"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import * as yup from "yup"

import cameraIcon from "../../../assets/camera.svg"
import { RecipeContext } from "../../../RecipeContext"
import SelectIngredients, {
  CommunityFullIngredientRecipeContent,
} from "../SelectIngredients/SelectIngredients"
import {
  CommunityFullIngredientRecipeContentToAPI,
  IngredientToCommunityFullIngredientRecipeContent,
} from "../SelectIngredients/utils/utils"
import {
  Body,
  Button,
  Fieldset,
  Heading,
  ImageCard,
  Loader,
  TextAreaInput,
} from "~/components/ui"
import { APICommunityStep } from "~/models/CommunityRecipe"
import {
  useDeleteRecipeIngredientsMutation,
  useRecipeIngredientsMutation,
} from "~/state/api/communityRecipe/communityRecipeIngredients"
import {
  useRecipeStepsMutation,
  useUpdateRecipeStepsMutation,
  useUploadRecipeStepPictureMutation,
} from "~/state/api/communityRecipe/communityRecipeSteps"
import getFormValidators from "~/utils/formValidation"

import css from "./StepForm.module.scss"

type EditableTextFormProps = {
  description: string
  image?: FileList | null
}

type UpdateStepInputReturn = {
  image: FileList | null
}

interface PropTypes {
  etapeIndex: number
  initialValue?: APICommunityStep
  step?: string
  close: () => void
}

function StepForm({ etapeIndex, step, initialValue, close }: PropTypes) {
  const intl = useIntl()
  const recipeContext = useContext(RecipeContext)

  const { requiredString } = getFormValidators(intl)

  const [imageUrl, setImageUrl] = useState<string | undefined>(
    initialValue?.image
  )
  const [imageFile, setImageFile] = useState<File | undefined>(undefined)

  const [selectedIngredientsContent, setSelectedIngredientsContent] = useState<
    CommunityFullIngredientRecipeContent[] | null
  >(
    initialValue?.ingredients && step
      ? IngredientToCommunityFullIngredientRecipeContent(
          initialValue.ingredients,
          step
        )
      : null
  )

  const [selectedIngredientsToDelete, setSelectedIngredientsToDelete] =
    useState<string[]>()

  useEffect(() => {
    setSelectedIngredientsContent(
      initialValue?.ingredients && initialValue.uuid
        ? IngredientToCommunityFullIngredientRecipeContent(
            initialValue.ingredients,
            initialValue.uuid
          )
        : null
    )
    setSelectedIngredientsToDelete([])
    setImageFile(undefined)
    setImageUrl(initialValue?.image)
  }, [initialValue])

  const [
    createRecipeStep,
    {
      isLoading: createIsLoading,
      isError: createIsError,
      error: createError,
      data: createData,
    },
  ] = useRecipeStepsMutation()

  const [
    deleteRecipeIngredient,
    {
      isLoading: deleteRecipeIngredientIsLoading,
      isError: deleteRecipeIngredientIsError,
      error: deleteRecipeIngredientError,
    },
  ] = useDeleteRecipeIngredientsMutation()

  const [
    updateRecipeStep,
    {
      isLoading: updateIsLoading,
      isError: updateIsError,
      error: updateError,
      isSuccess: updateIsSuccess,
    },
  ] = useUpdateRecipeStepsMutation()

  const [
    uploadRecipeStepPicture,
    {
      isLoading: isLoadingPictureUpload,
      isError: isErrorPictureUpload,
      error: errorPictureUpload,
      isSuccess: isSuccessPictureUpload,
    },
  ] = useUploadRecipeStepPictureMutation()

  const [
    createRecipeStepIngredients,
    {
      isLoading: createIngredientIsLoading,
      isError: isCreateIngredientError,
      error: createIngredientError,
    },
  ] = useRecipeIngredientsMutation()

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        description: requiredString,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { register, setValue, resetField, ...form } =
    useForm<EditableTextFormProps>({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        description: initialValue?.description || "",
      },
    })

  useEffect(() => {
    if (initialValue) {
      setValue("description", initialValue.description)
    } else {
      resetField("description")
    }
  }, [initialValue])

  const submit = async (values: EditableTextFormProps) => {
    let stepid = ""
    if (!initialValue) {
      recipeContext &&
        (await createRecipeStep({
          recipe: recipeContext.recipe.uuid,
          description: values.description,
        }).then(res => {
          if ("data" in res) {
            stepid = res.data.uuid
          }
        }))
    } else {
      stepid = initialValue.uuid
      recipeContext &&
        (await updateRecipeStep({
          uuid: initialValue.uuid,
          body: {
            recipe: recipeContext.recipe.uuid,
            description: values.description,
          },
        }))
    }
    if (imageFile && stepid != "") {
      const formData = new FormData()
      formData.append("image", imageFile)
      await uploadRecipeStepPicture({
        uuid: stepid,
        picture: formData,
      })
    }
    if (selectedIngredientsContent && stepid != "") {
      await Promise.all(
        selectedIngredientsContent.map(
          async (ingredient: CommunityFullIngredientRecipeContent) => {
            if (!ingredient.uuid) {
              const newIngredient =
                CommunityFullIngredientRecipeContentToAPI(ingredient)
              await createRecipeStepIngredients({
                ...newIngredient,
                recipe_step: stepid,
              })
            }
          }
        )
      )
    }
    if (selectedIngredientsToDelete && stepid != "") {
      await Promise.all(
        selectedIngredientsToDelete?.map(async (ingredient: string) => {
          await deleteRecipeIngredient({ uuid: ingredient })
        })
      )
    }
    recipeContext?.refetchRecipe()
    close()
  }

  const { errors } = form.formState

  const handlePictureChange = (values: UpdateStepInputReturn) => {
    if (values.image && values.image.length > 0) {
      setImageUrl(URL.createObjectURL(values.image[0]))
      setImageFile(values.image[0])
    }
  }

  const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code === "Enter") e.preventDefault()
  }

  const thereIsImage = imageUrl || initialValue?.image_thumbnail

  return (
    <form
      onSubmit={form.handleSubmit(submit)}
      onKeyDown={e => checkKeyDown(e)}
      noValidate
      className={css.root}
    >
      <Heading variant="extraLarge" className={css.title}>
        {intl.formatMessage({
          id: "recipe-creation/text:creation-step-heading",
        })}{" "}
        {etapeIndex + 1}
      </Heading>
      <Heading variant="medium">
        {intl.formatMessage({
          id: "recipe-creation/text:creation-step-description",
        })}
      </Heading>
      <Fieldset error={errors.description?.message}>
        <TextAreaInput
          id="description"
          {...register("description")}
          rows={3}
          placeholder={"Description"}
          errors={errors.description}
          className={css.inputDescription}
        />
      </Fieldset>
      <div className={css.row}>
        <Heading variant="medium">
          {intl.formatMessage({
            id: "recipe-creation/text:creation-step-ingredients",
          })}
        </Heading>
        <Body variant="body2" color="gray2">
          {intl.formatMessage({
            id: "recipe-creation/text:optional-field",
          })}
        </Body>
      </div>
      <SelectIngredients
        selectedIngredientsContent={selectedIngredientsContent}
        setSelectedIngredientsContent={setSelectedIngredientsContent}
        selectedIngredientsToDelete={selectedIngredientsToDelete}
        setSelectedIngredientsToDelete={setSelectedIngredientsToDelete}
      />
      <div className={cn(css.row, css.pictureHeading)}>
        <Heading variant="medium">
          {intl.formatMessage({
            id: "recipe-creation/text:creation-step-picture",
          })}
        </Heading>
        <Body variant="body2" color="gray2">
          {intl.formatMessage({
            id: "recipe-creation/text:optional-field",
          })}
        </Body>
      </div>
      <>
        <input
          id="image"
          {...register("image")}
          type="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={e => handlePictureChange({ image: e.target.files })}
          style={{ display: "none" }}
        />
      </>

      <label htmlFor="image" className={css.inputLabel}>
        {thereIsImage ? (
          <div className={css.thumbnail}>
            <ImageCard
              imageProps={{
                src: thereIsImage,
              }}
            />
            <span className={css.editIcon} />
          </div>
        ) : (
          <div
            className={css.imageCard}
            style={thereIsImage ? { border: "none" } : {}}
          >
            <div className={css.fileInput}>
              <img loading="lazy" src={cameraIcon} alt="" />
              <Body variant="body2" semiBold color="gray2">
                {intl.formatMessage({
                  id: "recipe-creation/text:select-picture",
                })}
              </Body>
            </div>
          </div>
        )}
      </label>
      <div className={css.submit}>
        <Button variant="primary" color="original" type="submit" fullWidth>
          {isLoadingPictureUpload || updateIsLoading || createIsLoading ? (
            <div className={css.loader}>
              <Loader />
            </div>
          ) : (
            <>
              {intl.formatMessage({
                id: "recipe-creation/action:creation-step-submit",
              })}
            </>
          )}
        </Button>
      </div>
    </form>
  )
}

export default StepForm
