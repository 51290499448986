import React, { useContext } from "react"

import { useIntl } from "react-intl"

import RecipeIngredientList from "../../RecipeIngredientList/RecipeIngredientList"
import { RecipeContext } from "../RecipeContext"
import { Body, Heading } from "~/components/ui"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./RecipeCreationIngredientsIndication.module.scss"

const RecipeCreationIngredientsIndication = () => {
  const intl = useIntl()
  const recipeContext = useContext(RecipeContext)
  return (
    <div className={css.root}>
      <Heading variant="medium">
        {intl.formatMessage({
          id: "recipe-creation/text:ingredients-heading",
        })}
      </Heading>
      <Body variant="body1" color="gray2" noMargin>
        {intl.formatMessage({
          id: "recipe-creation/text:ingredients-description",
        })}
      </Body>
      <RecipeIngredientList
        ingredients={recipeContext?.recipe.ingredients_v2}
        numberOfPeople={recipeContext?.recipe.number_of_people}
        inRecipeCreation
      />
    </div>
  )
}

export default RecipeCreationIngredientsIndication
