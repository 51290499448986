import React, { useCallback, useContext, useEffect, useState } from "react"

import cn from "classnames"
import debounce from "lodash/debounce"
import { useIntl } from "react-intl"

import useAutosavePopUp from "../hooks/useAutosavePopUp"
import { RecipeContext } from "../RecipeContext"
import { Body, Loader } from "~/components/ui"
import { useUpdateUserRecipeMutation } from "~/state/api/communityRecipes"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./TogglePublicRecipe.module.scss"

const DEBOUNCE_TIME = 500

const TogglePublicRecipe = () => {
  const intl = useIntl()
  const recipeContext = useContext(RecipeContext)

  const [isChecked, setIsChecked] = useState(
    recipeContext?.recipe.is_draft ?? false
  )

  const [updateData, { isLoading, isError, error, isSuccess }] =
    useUpdateUserRecipeMutation()

  const isRecipeSubmit =
    recipeContext?.recipe.status === "submitted" ||
    recipeContext?.recipe.status === "validated"

  useEffect(() => {
    setIsChecked(recipeContext?.recipe.is_draft ?? false)
  }, [recipeContext?.recipe.is_draft])

  useAutosavePopUp({
    isSuccess,
    isLoading,
    isError,
    error,
  })

  const updateIsDraft = useCallback(
    debounce((isDraft: boolean) => {
      recipeContext?.recipe.uuid &&
        updateData({
          uuid: recipeContext.recipe.uuid,
          body: {
            is_draft: isDraft,
          },
        })
    }, DEBOUNCE_TIME),
    []
  )

  const toggleIsDraft = () => {
    const newValue = !isChecked
    if (!isRecipeSubmit) {
      setIsChecked(newValue)
      updateIsDraft(newValue)
    }
  }

  return (
    <div className={css.root}>
      <div className={css.toggle}>
        <Body variant="body1">
          {intl.formatMessage({
            id: "recipe-creation/text:public-recipe",
          })}
        </Body>
        <div className={css.switch} onClick={toggleIsDraft}>
          <span
            className={cn(css.slider, {
              [css.checked]: !isChecked,
              [css.submitted]: isRecipeSubmit,
            })}
          ></span>
        </div>
      </div>

      <Body variant="body3" color="gray1">
        {!isChecked
          ? intl.formatMessage({
              id: "recipe-creation/text:validation-pending",
            })
          : intl.formatMessage({
              id: "recipe-creation/text:pass-public",
            })}
      </Body>
      {isLoading && <Loader />}
    </div>
  )
}

export default TogglePublicRecipe
