import React, { useContext, useEffect, useState } from "react"

import { useIntl } from "react-intl"

import { dietsIcons } from "../../RecipeDiets/RecipeDiets"
import useAutosavePopUp from "../hooks/useAutosavePopUp"
import { RecipeContext } from "../RecipeContext"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import { Body, Heading } from "~/components/ui"
import { Diet } from "~/models/CommunityRecipe"
import {
  useCommunityRecipeDietsQuery,
  useUpdateCommunityRecipeDietsMutation,
} from "~/state/api/communityRecipe/communityRecipeDiets"
import { capitalizeFirstLetter } from "~/utils/string"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./RecipeUpdateDiets.module.scss"

export function RecipeUpdateDiets() {
  const intl = useIntl()
  const recipeContext = useContext(RecipeContext)

  const [selectedDiets, setSelectedDiets] = useState<Diet[]>()

  const { data: apiCommunityDiets, isSuccess: isSuccessGet } =
    useCommunityRecipeDietsQuery({
      language: intl.locale,
    })

  const [
    updateCommunityRecipeDiets,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateCommunityRecipeDietsMutation()

  const onGetSuccess = () => {
    const tempSelectedDiets = apiCommunityDiets?.filter(diet =>
      recipeContext?.recipe.suitable_for_diets.some(d => d.slug === diet.slug)
    )

    setSelectedDiets(tempSelectedDiets)
  }

  useAutosavePopUp({
    isSuccess: isSuccessUpdate,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
  })

  useEffect(() => {
    if (isSuccessGet) {
      onGetSuccess()
    }
  }, [apiCommunityDiets, isSuccessGet, recipeContext?.recipe])

  const handleClickOnDiet = (diet: Diet, isSelected?: boolean) => {
    let dietsSelected = selectedDiets ?? []
    if (isSelected && selectedDiets) {
      dietsSelected = selectedDiets.filter(d => d !== diet)
    } else {
      dietsSelected = [diet].concat(selectedDiets ?? [])
    }
    const diets = dietsSelected?.map(diet => {
      return { uuid: diet }
    })

    recipeContext &&
      updateCommunityRecipeDiets({
        uuid: recipeContext.recipe.uuid,
        suitable_for_diets: diets.map(d => d.uuid),
      })
  }

  return (
    <div className={css.root}>
      <div className={css.heading}>
        <Heading variant="medium" Tag="h3">
          {intl.formatMessage({
            id: "recipe-creation/text:diets-heading",
          })}
        </Heading>
        <Body variant="body2" color="gray2">
          {intl.formatMessage({
            id: "recipe-creation/text:optional-field",
          })}
        </Body>
      </div>
      <div className={css.diets}>
        {apiCommunityDiets?.map((diet, index) => {
          const isSelected = recipeContext?.recipe.suitable_for_diets.some(
            d => d.slug === diet.slug
          )

          const dietIcon = dietsIcons.get(diet.slug)

          const color = isSelected
            ? "var(--original-color)"
            : "var(--gray2-color)"

          return (
            <div
              key={index}
              className={css.specificity}
              style={{
                borderColor: color,
              }}
              onClick={() => handleClickOnDiet(diet, isSelected)}
            >
              <ColorableSVG
                className={css.icon}
                href={dietIcon}
                mainColor={color}
                width="23"
                height="23"
                title={diet.translation}
              />
              <Body variant="body2" color={color} semiBold>
                {capitalizeFirstLetter(diet.translation)}
              </Body>
            </div>
          )
        })}
      </div>
    </div>
  )
}
