import React from "react"

import cn from "classnames"
import { useIntl } from "react-intl"

import StepForm from "./StepForm/StepForm"
import Modal from "~/components/Modals/Modal"
import { APICommunityStep } from "~/models/CommunityRecipe"
import { cancelScrollOnClose } from "~/utils/modals"

import css from "./NewStepModal.module.scss"

interface PropTypes {
  etapeIndex: number
  open: boolean
  initialValue?: APICommunityStep
  step?: string
  onClose: () => void
}

function NewStepModal({
  etapeIndex,
  open,
  step,
  initialValue,
  onClose,
}: PropTypes) {
  const intl = useIntl()

  const close = () => {
    onClose()

    cancelScrollOnClose()
  }

  return (
    <Modal open={open} onClose={close} mode="fixed">
      {(modalRef, open) => (
        <div
          className={cn(css.modal, { [css.openedModal]: open })}
          ref={modalRef}
        >
          <StepForm
            etapeIndex={etapeIndex}
            initialValue={initialValue}
            step={step}
            close={close}
          />
          <button
            className={css.close}
            onClick={close}
            title={intl.formatMessage({ id: "action:close" })}
          />
        </div>
      )}
    </Modal>
  )
}

export default NewStepModal
