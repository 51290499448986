import React, { useEffect, useState } from "react"

import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"

import { PrismicBanners } from "../../components/Banner/utils"
import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import RecipeCarousel from "./RecipeCarousel/RecipeCarousel"
import RecipeCreation from "./RecipeCreation/RecipeCreation"
import RecipePage from "./RecipePage"
import Link from "~/components/Link/Link"
import { Button, Container, Heading, Loader } from "~/components/ui"
import { navigate } from "~/gatsby/navigate"
import useCategories from "~/hooks/useCategories"
import useIsClient from "~/hooks/useIsClient"
import { apiCommunityRecipeToRecipe } from "~/models/CommunityRecipe"
import { PartialRecipe } from "~/models/PartialRecipe"
import { apiRecipeToRecipe } from "~/models/Recipe"
import { Verticals } from "~/models/Vertical"
import { useGetUserRecipeQuery } from "~/state/api/communityRecipes"
import { useGetRecipesQuery } from "~/state/api/recipesApi"
import { userSelector } from "~/state/modules/userInfo"
import {
  ChefclubLocale,
  chefclubLocaleArray,
  getRedirectLanguage,
} from "~/utils/locales"

import css from "./RecipeCSRPage.module.scss"

interface ClientRecipeCSRPageProp {
  recipeVertical: string
  recipeId: string
  recipeSlug: string
  data: RecipeQuery
}

export function ClientRecipeCSRPage({
  data,
  recipeId,
  recipeSlug,
  recipeVertical,
}: ClientRecipeCSRPageProp) {
  const intl = useIntl()
  const CATEGORIES = useCategories(intl.locale)
  const [doesntExist, setDoesntExist] = useState(false)

  const urlParams = new URLSearchParams(window.location.search)
  const editMode = urlParams.get("em")

  const {
    data: apiRecipes,
    isLoading: isLoadingRecipes,
    isSuccess,
  } = useGetRecipesQuery({
    in_id: [recipeId],
    allproperties: "true",
  })

  const {
    data: apiCommunityRecipe,
    isLoading: apiCommunityRecipeLoading,
    refetch: refetchRecipe,
  } = useGetUserRecipeQuery({
    uuid: recipeId,
    lang: intl.locale,
  })

  const { data: apiSameCategoryVerticalCarousel } = useGetRecipesQuery(
    {
      _limit: 10,
      language: intl.locale,
      vertical: recipeVertical,
      contains_categories: apiRecipes?.data.length
        ? apiRecipes?.data[0].primary_category.uuid
        : null,
    },
    { skip: !isSuccess }
  )

  const sameCategoryVerticalCarousel = apiSameCategoryVerticalCarousel?.data
    ? {
        totalCount: apiSameCategoryVerticalCarousel?.data
          ? apiSameCategoryVerticalCarousel?.data.length
          : 0,
        nodes: apiSameCategoryVerticalCarousel?.data.map(apiRecipeToRecipe),
      }
    : undefined

  const { data: apiSameCategoryNotVerticalCarousel } = useGetRecipesQuery(
    {
      _limit: 10,
      language: intl.locale,
      in_vertical: Verticals.filter(
        vertical => vertical !== recipeVertical
      ).join(","),
      contains_categories: apiRecipes?.data.length
        ? apiRecipes?.data[0].primary_category.uuid
        : null,
    },
    { skip: !isSuccess }
  )
  const sameCategoryNotVerticalCarousel =
    apiSameCategoryNotVerticalCarousel?.data
      ? {
          totalCount: apiSameCategoryNotVerticalCarousel?.data
            ? apiSameCategoryNotVerticalCarousel?.data.length
            : 0,
          nodes:
            apiSameCategoryNotVerticalCarousel?.data.map(apiRecipeToRecipe),
        }
      : undefined

  useEffect(() => {
    if (
      !isLoadingRecipes &&
      !apiCommunityRecipeLoading &&
      apiRecipes &&
      apiRecipes?.data[0] == undefined &&
      apiCommunityRecipe == undefined
    ) {
      setDoesntExist(true)
    }
  }, [
    apiRecipes,
    apiCommunityRecipe,
    apiCommunityRecipeLoading,
    isLoadingRecipes,
  ])

  const user = useSelector(userSelector)

  const recipe = apiRecipes?.data.length
    ? apiRecipeToRecipe(apiRecipes?.data[0])
    : apiCommunityRecipe
    ? apiCommunityRecipeToRecipe(apiCommunityRecipe)
    : undefined

  const isOwnCommunityRecipe =
    !!apiCommunityRecipe && apiCommunityRecipe.author?.user_id === user?.id

  return editMode == "true" && user && isOwnCommunityRecipe ? (
    <div>
      <RecipeCreation
        recipe={apiCommunityRecipe}
        user={user}
        refetchRecipe={refetchRecipe}
      />
    </div>
  ) : recipe && chefclubLocaleArray.includes(recipe.language) ? (
    <RecipePage
      data={{
        recipe: {
          ...recipe,
          sameCategoryRecipes: [
            ...(sameCategoryVerticalCarousel || { nodes: [] }).nodes,
            ...(sameCategoryNotVerticalCarousel || { nodes: [] }).nodes,
          ].filter(
            (recipe, index, arr) =>
              index === arr.findIndex(r => r.id === recipe.id)
          ),
          otherCategoryRecipes: [],
        },
        primaryCategory: recipe?.primaryCategory?.uuid
          ? CATEGORIES[recipe.primaryCategory.uuid]
          : undefined,
        prismicBanner: data.prismicBanner,
        alcoholCategory: data.appSettings?.alcohol_category
          ? CATEGORIES[data.appSettings?.alcohol_category]
          : undefined,
      }}
      language={apiRecipes?.data[0]?.language as ChefclubLocale}
      location={location}
      isSSG={false}
      isOwnCommunityRecipe={isOwnCommunityRecipe}
    />
  ) : doesntExist ? (
    <Container className={css.root}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Heading Tag="h1" align="center">
        {intl.formatMessage({ id: "recipe/not-found/text:does-not-exist" })}
      </Heading>
      <RecipeCarousel recipes={data.someRecipes.nodes} underline={false} />
      <div className={css.backToSearch}>
        <Link to={"recipes"}>
          <Button>
            {intl.formatMessage({ id: "recipe/not-found/text:back-to-search" })}
          </Button>
        </Link>
      </div>
    </Container>
  ) : (
    <div>
      <Loader />
    </div>
  )
}

interface PropTypes extends TemplateDefaultPropTypes {
  data: RecipeQuery
}

const RecipeCSRPage = ({ data }: PropTypes) => {
  const isClient = useIsClient()

  if (isClient) {
    const pathnameParts = location.pathname.split("/")
    const [recipeVertical, recipeId, recipeSlug] = pathnameParts.slice(3, 6)

    if (!recipeId) {
      const urlLang = getRedirectLanguage()
      navigate(`/${urlLang}/c/`, { replace: true })
    }

    return !recipeId ? (
      <div>
        <Loader />
      </div>
    ) : (
      <ClientRecipeCSRPage
        data={data}
        recipeId={recipeId}
        recipeSlug={recipeSlug}
        recipeVertical={recipeVertical}
      />
    )
  } else {
    return (
      <div>
        <Loader />
      </div>
    )
  }
}

interface RecipeQuery {
  someRecipes: { nodes: PartialRecipe[] }
  prismicBanner: PrismicBanners | null
  appSettings: {
    alcohol_category: string
  }
}

//You can find the fragment definition in src/components/Banner/Banner.tsx
export const query = graphql`
  query RecipeCSRPage($language: String, $prismicLanguage: String) {
    someRecipes: allRecipe(filter: { language: { eq: $language } }, limit: 10) {
      nodes {
        ...PartialRecipe
      }
    }
    prismicBanner(
      data: { banner_type: { eq: "recipe_page" } }
      lang: { eq: $prismicLanguage }
    ) {
      ...Banner
    }
    appSettings(lang: { eq: $prismicLanguage }) {
      alcohol_category
    }
  }
`
export default withTemplate(RecipeCSRPage)
