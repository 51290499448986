import React, { useContext, useEffect, useState } from "react"

import { useIntl } from "react-intl"

import useAutosavePopUp from "../hooks/useAutosavePopUp"
import { RecipeContext } from "../RecipeContext"
import SliderRange from "./SliderRange/SliderRange"
import { Body, Button, Heading } from "~/components/ui"
import { useUpdateUserRecipeMutation } from "~/state/api/communityRecipes"
import RecipePrepInfo from "~/templates/Recipe/RecipePrepInfo/RecipePrepInfo"

import css from "./RecipeUpdatePrepInfo.module.scss"

type RecipeUpdatePrepInfoProps = {
  totalMinutes: number
  preparationMinutes: number | null
  cookingMinutes: number | null
  restingMinutes: number | null
}

export function RecipeUpdatePrepInfo({ ...props }: RecipeUpdatePrepInfoProps) {
  const intl = useIntl()
  const recipeContext = useContext(RecipeContext)

  const [editMode, setEditMode] = useState(false)
  const [preparationMinutes, setPreparationMinutes] = useState(
    props.preparationMinutes ?? 0
  )
  const [cookingMinutes, setCookingMinutes] = useState(
    props.cookingMinutes ?? 0
  )
  const [restingMinutes, setRestingMinutes] = useState(
    props.restingMinutes ?? 0
  )
  const [totalMinutes, setTotalMinutes] = useState(props.totalMinutes ?? 0)

  const resetToProps = () => {
    setPreparationMinutes(props.preparationMinutes ?? 0)
    setCookingMinutes(props.cookingMinutes ?? 0)
    setRestingMinutes(props.restingMinutes ?? 0)
    setTotalMinutes(props.totalMinutes ?? 0)
  }

  const [updateData, { isLoading, isSuccess, isError, error }] =
    useUpdateUserRecipeMutation()

  useAutosavePopUp({
    isSuccess,
    isLoading,
    isError,
    error,
    onSuccess: () => setEditMode(false),
  })

  const submit = () => {
    recipeContext?.recipe.uuid &&
      updateData({
        uuid: recipeContext?.recipe.uuid,
        body: {
          preparation_minutes: preparationMinutes,
          cooking_minutes: cookingMinutes,
          resting_minutes: restingMinutes,
        },
      })
  }

  useEffect(() => {
    setTotalMinutes(preparationMinutes + cookingMinutes + restingMinutes)
  }, [preparationMinutes, cookingMinutes, restingMinutes])

  return (
    <div
      className={css.root}
      onClick={() => {
        if (!editMode) {
          setEditMode(true)
        }
      }}
      style={{ cursor: editMode ? "default" : "pointer" }}
    >
      {editMode ? (
        <div className={css.editContainer}>
          <Heading variant="small">
            {intl.formatMessage({
              id: "recipe/text:preparation-time",
            })}
          </Heading>
          <SliderRange
            value={preparationMinutes}
            setValue={setPreparationMinutes}
          />
          <Heading variant="small">
            {intl.formatMessage({
              id: "recipe/text:cooking-time",
            })}
          </Heading>
          <SliderRange value={cookingMinutes} setValue={setCookingMinutes} />
          <Heading variant="small">
            {intl.formatMessage({
              id: "recipe/text:resting-time",
            })}
          </Heading>
          <SliderRange value={restingMinutes} setValue={setRestingMinutes} />
          <div className={css.cta}>
            <Body
              variant="body3"
              semiBold
              className={css.cancel}
              color="gray2"
              onClick={() => {
                resetToProps()
                setEditMode(false)
              }}
            >
              {intl.formatMessage({
                id: "form/action:cancel",
              })}
            </Body>
            <Button
              variant="secondary"
              color="original"
              size="small"
              className={css.validate}
              onClick={() => submit()}
            >
              {intl.formatMessage({
                id: "form/action:submit",
              })}
            </Button>
          </div>
        </div>
      ) : (
        <RecipePrepInfo
          totalMinutes={totalMinutes}
          preparationMinutes={preparationMinutes}
          cookingMinutes={cookingMinutes}
          restingMinutes={restingMinutes}
          editeable
        />
      )}
    </div>
  )
}
