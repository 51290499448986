import React from "react"

import { HitsProvided } from "react-instantsearch-core"
import { connectHits } from "react-instantsearch-dom"

import Separator from "~/components/Separator/Separator"
import { Body } from "~/components/ui"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./CustomSelectionHits.module.scss"

export type PartialHit = {
  objectID: string
  name: string
  uuid?: string
}

export type PartialHitTranslation = {
  objectID: string
  name: string
  uuid?: string
  language: string
  region_of_origin: {
    uuid: string
    slug: string
  }
}

type PropTypes = HitsProvided<PartialHit | PartialHitTranslation> & {
  setSelection: (selection: PartialHit | undefined) => void
}
const Hits = ({ hits, setSelection }: PropTypes) => {
  return (
    <div className={css.root}>
      {hits.map((hit, index) => (
        <div
          className={css.hit}
          key={hit.objectID}
          onMouseDown={() => {
            setSelection(hit)
          }}
        >
          <div className={css.separator}>
            {index !== 0 && <Separator noMargin />}
          </div>
          <Body variant="body1" className={css.hitName} noMargin>
            {hit.name}
          </Body>
        </div>
      ))}
    </div>
  )
}
export const CustomSelectionHits = connectHits<PropTypes, any>(Hits)
