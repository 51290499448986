import React, { useState } from "react"

import { useIntl } from "react-intl"

import AutocompleteSearch from "../../Algolia/AutocompleteSearch/AutocompleteSearch"
import { PartialHit } from "../../Algolia/CustomSelectionHits/CustomSelectionHits"
import Quantity from "../../Algolia/Quantity/Quantity"
import useAutosavePopUp from "../../hooks/useAutosavePopUp"
import { Body, Button } from "~/components/ui"
import { useRecipeKitchenwareMutation } from "~/state/api/communityRecipe/communityRecipeKitchenwares"
import { ErrorResponse, ErrorResponseDjango } from "~/types/global-types"
import { getErrorMessage } from "~/utils/errors"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./SelectKitchenwares.module.scss"

const GATSBY_ALGOLIA_KITCHENWARES_INDEX =
  process.env.GATSBY_ALGOLIA_KITCHENWARES_INDEX ?? ""

export type KitchenwareAlgolia = {
  objectID: string
  url: string
  name: string
  plural_name: string
  language: string
}

interface PropTypes {
  toggleEditMode: () => void
  uuid: string
}

const DEFAULTQUANTITY = 1
const SelectKitchenwares = ({ toggleEditMode, uuid }: PropTypes) => {
  const intl = useIntl()
  const [selectedKitchenware, setSelectedKitchenware] = useState<PartialHit>()
  const [quantity, setQuantity] = useState<number | null>(DEFAULTQUANTITY)

  const [createRecipeKitchenware, { isLoading, isError, isSuccess, error }] =
    useRecipeKitchenwareMutation()

  useAutosavePopUp({
    isSuccess,
    isLoading,
    isError,
    error,
    onSuccess: toggleEditMode,
  })

  const handleSubmit = () => {
    if (selectedKitchenware && quantity) {
      createRecipeKitchenware({
        quantity: quantity,
        kitchenware: { uuid: selectedKitchenware.objectID },
        recipe: uuid,
      })
    }
  }
  return (
    <div className={css.root}>
      <div className={css.rowDesktop}>
        <div className={css.kitchenware}>
          <AutocompleteSearch
            index={GATSBY_ALGOLIA_KITCHENWARES_INDEX}
            setSelection={setSelectedKitchenware}
            selection={selectedKitchenware}
            onBlur={() => setSelectedKitchenware(undefined)}
            placeholder={intl.formatMessage({
              id: "recipe-creation/text:kitchenwares-select",
            })}
          />
        </div>
        <div className={css.quantity}>
          <Quantity setQuantity={setQuantity} defaultValue={DEFAULTQUANTITY} />
        </div>
      </div>
      {error && (
        <div>
          {getErrorMessage(
            (
              error as unknown as {
                data?: ErrorResponse | ErrorResponseDjango
              }
            )?.data
          ) ?? "Erreur"}
        </div>
      )}
      <div className={css.buttons}>
        <Body
          variant="body2"
          color="gray2"
          onClick={toggleEditMode}
          className={css.cancel}
        >
          {intl.formatMessage({
            id: "form/action:cancel",
          })}
        </Body>
        <Button
          variant="secondary"
          color={selectedKitchenware && quantity ? "original" : "gray2"}
          size="small"
          className={css.submit}
          onClick={handleSubmit}
        >
          {intl.formatMessage({
            id: "form/action:submit",
          })}
        </Button>
      </div>
    </div>
  )
}

export default SelectKitchenwares
