import React, { useContext } from "react"

import { useIntl } from "react-intl"

import cameraIcon from "../assets/camera.svg"
import useAutosavePopUp from "../hooks/useAutosavePopUp"
import { RecipeContext } from "../RecipeContext"
import { Body, Loader } from "~/components/ui"
import { useUploadRecipePictureMutation } from "~/state/api/communityRecipes"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./RecipeUpdatePicture.module.scss"

type UpdateRecipeInputReturn = {
  recipePicture: FileList | null
}

export function RecipeUpdatePicture() {
  const intl = useIntl()
  const recipeContext = useContext(RecipeContext)
  const recipe = recipeContext?.recipe

  const [uploadRecipePicture, { isLoading, isError, error, isSuccess }] =
    useUploadRecipePictureMutation()

  useAutosavePopUp({
    isSuccess,
    isLoading,
    isError,
    error,
  })

  const submitNewPicture = async (values: UpdateRecipeInputReturn) => {
    if (values.recipePicture && values.recipePicture.length > 0) {
      const formData = new FormData()
      const file = values.recipePicture[0]
      formData.append("web_cover", file)
      if (recipe) {
        await uploadRecipePicture({
          uuid: recipe?.uuid,
          picture: formData,
        })
      }
    }
  }

  return (
    <div className={css.root}>
      <>
        <input
          id="recipePicture"
          name="recipePicture"
          type="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={e => submitNewPicture({ recipePicture: e.target.files })}
          style={{ display: "none" }}
        />
      </>

      <label htmlFor="recipePicture" className={css.inputLabel}>
        <div
          className={css.imageCard}
          style={recipe?.web_cover ? { border: "none" } : {}}
        >
          {isLoading ? (
            <div className={css.loader}>
              <Loader color="original" />
            </div>
          ) : !recipe?.web_cover ? (
            <div className={css.fileInput}>
              <img loading="lazy" src={cameraIcon} alt="" />
              <Body variant="body2" semiBold color="gray2">
                {intl.formatMessage({
                  id: "recipe-creation/text:select-picture",
                })}
              </Body>
            </div>
          ) : (
            <>
              <img
                className={css.webCover}
                loading="lazy"
                src={recipe.web_cover}
                alt=""
              />
              <span className={css.editIcon} />
            </>
          )}
        </div>
      </label>
      {isError && error && "message" in error && (
        <Body color="original">{error.message}</Body>
      )}
    </div>
  )
}
