import React, { useEffect, useState } from "react"

import cn from "classnames"
import { SearchBoxProvided } from "react-instantsearch-core"
import { connectSearchBox } from "react-instantsearch-dom"

import css from "./CustomSearchBox.module.scss"

type propType = SearchBoxProvided & {
  setOnFocus: (focus: boolean) => void
  isFocus: boolean
  initialValue?: string
  clearSelect?: () => void
  placeholder?: string
}

const SearchBox = ({
  currentRefinement,
  refine,
  setOnFocus,
  isFocus,
  initialValue,
  clearSelect,
  placeholder,
}: propType) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    initialValue
  )
  useEffect(() => {
    if (initialValue === undefined) {
      refine("")
    }
    setSelectedValue(initialValue)
  }, [initialValue])

  return (
    <input
      type="text"
      value={selectedValue ?? currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
      onFocus={() => {
        setOnFocus(true)
        setSelectedValue(undefined)
        clearSelect && clearSelect()
      }}
      onBlur={() => setOnFocus(false)}
      className={cn(css.searchBox, {
        [css.arrowDown]: !isFocus,
        [css.arrowUp]: isFocus,
      })}
      placeholder={placeholder}
    />
  )
}

const CustomSearchBox = connectSearchBox<propType>(SearchBox)

export default CustomSearchBox
