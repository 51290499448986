import React, { useContext } from "react"

import { useIntl } from "react-intl"

import useAutosavePopUp from "../hooks/useAutosavePopUp"
import { RecipeContext } from "../RecipeContext"
import ErrorMessage from "~/components/ErrorMessage/ErrorMessage"
import { Button, Loader } from "~/components/ui"
import { useUpdateUserRecipeMutation } from "~/state/api/communityRecipes"

import css from "./SubmitRecipe.module.scss"

const SubmitRecipe = () => {
  const intl = useIntl()
  const recipeContext = useContext(RecipeContext)

  const [updateData, { isLoading, isError, error, isSuccess }] =
    useUpdateUserRecipeMutation()

  useAutosavePopUp({
    isSuccess,
    isLoading,
    isError,
    error,
  })

  const handleClick = async () => {
    const draftArg = recipeContext?.recipe.is_draft
      ? {
          is_draft: false,
        }
      : {}

    recipeContext?.recipe.uuid &&
      (await updateData({
        uuid: recipeContext?.recipe.uuid,
        body: {
          status: "submitted",
          ...draftArg,
        },
      }))
  }

  const isRecipeSubmitted =
    recipeContext?.recipe.status === "submitted" ||
    recipeContext?.recipe.status === "validated"

  const isRecipeBlocked =
    recipeContext?.recipe.status == "blocked" ? true : false

  const moderationSendable =
    recipeContext?.recipe.is_complete &&
    !recipeContext?.recipe.is_draft &&
    !isRecipeBlocked &&
    !isRecipeSubmitted

  return (
    <div className={css.root}>
      <Button
        color={moderationSendable ? "original" : "gray4"}
        onClick={() => moderationSendable && handleClick()}
        fullWidth
      >
        {isRecipeSubmitted ? (
          intl.formatMessage({
            id: "recipe-creation/action:sent-in-moderation",
          })
        ) : isLoading ? (
          <div className={css.loader}>
            <Loader />
          </div>
        ) : (
          intl.formatMessage({
            id: "recipe-creation/action:send-moderation",
          })
        )}
      </Button>
      {!moderationSendable &&
        recipeContext?.recipe.validation_errors.map((error, index) => (
          <ErrorMessage
            key={index}
            error={intl.formatMessage({
              id: `recipe-creation/error:${error}`,
            })}
          />
        ))}
    </div>
  )
}

export default SubmitRecipe
