import React, { useEffect, useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import cn from "classnames"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import * as yup from "yup"

import errorIcon from "../assets/error.svg"
import penIcon from "../assets/pen.svg"
import useAutosavePopUp from "../hooks/useAutosavePopUp"
import {
  Body,
  Fieldset,
  Heading,
  HeadingVariant,
  TextInput,
} from "~/components/ui"
import { useUpdateUserRecipeMutation } from "~/state/api/communityRecipes"
import { ErrorResponse, ErrorResponseDjango } from "~/types/global-types"
import { getErrorMessage } from "~/utils/errors"
import getFormValidators from "~/utils/formValidation"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./RecipeUpdateTextField.module.scss"

type EditableTextFormProps = {
  [objectKey: string]: string
}

type RecipeUpdateTextFieldProps = {
  initialValue: EditableTextFormProps
  placeholder: string
  apiKey: "title" | "subtitle"
  variant: HeadingVariant
  defaultValue?: string
  required?: boolean
  emptyText?: string
  recipeUuid: string
}

//Useable for subtitle
export function RecipeUpdateTextField({
  initialValue,
  placeholder,
  apiKey,
  defaultValue,
  required = true,
  emptyText,
  variant,
  recipeUuid,
}: RecipeUpdateTextFieldProps) {
  const intl = useIntl()

  const [editMode, setEditMode] = useState<boolean>(false)
  const [value, setValue] = useState<EditableTextFormProps>(initialValue)
  const [isChange, setIsChange] = useState(false)

  const [updateData, { isLoading, isError, error, isSuccess, data }] =
    useUpdateUserRecipeMutation()

  const { requiredString } = getFormValidators(intl)
  const validationSchema = useMemo(
    () =>
      yup.object().shape(
        required
          ? {
              [apiKey]: requiredString,
            }
          : {}
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { register, handleSubmit, getValues, ...form } =
    useForm<EditableTextFormProps>({
      mode: "all",
      resolver: yupResolver(validationSchema),
      defaultValues:
        value[apiKey] === "" && defaultValue
          ? {
              [apiKey]: defaultValue,
            }
          : value,
    })

  useAutosavePopUp({
    isSuccess,
    isLoading,
    isError,
    error,
    onSuccess: () => {
      if (data) {
        const newValue = { ...value }
        newValue[apiKey] = data[apiKey]
        data && setValue(newValue)
      }
      setEditMode(false)
    },
  })

  const submit = async (values: EditableTextFormProps) => {
    await updateData({
      uuid: recipeUuid,
      body: {
        [apiKey]: values[apiKey],
      },
    })
  }

  const { errors, isSubmitted } = form.formState

  return (
    <div>
      <div className={css.root}>
        {editMode ? (
          <form
            onBlur={handleSubmit(submit)}
            onSubmit={handleSubmit(submit)}
            noValidate
          >
            <Fieldset error={errors[apiKey]?.message}>
              <TextInput
                {...register(apiKey, { onChange: () => setIsChange(true) })}
                autoFocus
                placeholder={placeholder}
                errors={errors[apiKey]}
                className={cn(css.inputText, {
                  [css.inputError]: isError && isSubmitted && !isChange,
                })}
              />
            </Fieldset>
            {/* This error message is displayed in english */}
            {isError && isSubmitted && !isChange && (
              <div className={css.messageWrap}>
                <img loading="lazy" src={errorIcon} alt="" />
                <Body variant="body3" color="original" noMargin>
                  {getErrorMessage(
                    (
                      error as unknown as {
                        data?: ErrorResponse | ErrorResponseDjango
                      }
                    )?.data
                  ) ?? "Erreur"}
                </Body>
              </div>
            )}
          </form>
        ) : (
          <div
            className={css.editableTextField}
            onClick={() => setEditMode(true)}
          >
            <Heading className={css.actualValue} variant={variant}>
              {(value[apiKey] || emptyText) ?? ""}
            </Heading>
            <img loading="lazy" src={penIcon} alt="" />
          </div>
        )}
      </div>
    </div>
  )
}
