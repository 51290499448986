import React, { useContext } from "react"

import cn from "classnames"

import { PopUpContext } from "../PopUpContext"
import errorIcon from "./assets/error.svg"
import successIcon from "./assets/success.svg"
import { Body } from "~/components/ui"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./RecipeAutosavePopUp.module.scss"

const RecipeAutosavePopUp = () => {
  const popUp = useContext(PopUpContext)

  const type = popUp?.content?.type

  return popUp?.content ? (
    <div className={css.root}>
      <div
        className={cn(css.popUp, {
          [css.successBg]: type === "saved",
          [css.errorBg]: type === "error",
        })}
      >
        {popUp.content.type === "saved" && (
          <div className={css.success}>
            <img loading="lazy" src={successIcon} alt="" />
            <Body variant="body3" color="#00C7BB">
              {popUp.content.message ??
                "Votre recette a été sauvegardée automatiquement"}
            </Body>
          </div>
        )}
        {popUp.content.type === "error" && (
          <div className={css.error}>
            <img loading="lazy" src={errorIcon} alt="" />
            <Body variant="body3" color="original">
              {popUp.content.message ?? "Erreur"}
            </Body>
          </div>
        )}
      </div>
    </div>
  ) : null
}

export default RecipeAutosavePopUp
