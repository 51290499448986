import React, { useContext, useState } from "react"

import cn from "classnames"
import { useIntl } from "react-intl"

import penIcon from "../assets/pen.svg"
import CategoryStep from "../NewRecipeModal/CategoryStep/CategoryStep"
import { RecipeContext } from "../RecipeContext"
import Modal from "~/components/Modals/Modal"
import { Heading } from "~/components/ui"
import { cancelScrollOnClose } from "~/utils/modals"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./RecipeUpdateCategory.module.scss"

const RecipeUpdateCategory = () => {
  const intl = useIntl()

  const recipeContext = useContext(RecipeContext)
  const recipe = recipeContext?.recipe

  //If the actual category is General, we open the category modal
  const isGeneral = recipe?.categories.length
    ? recipe?.categories[0].slug === "general"
    : true

  const [openModal, setOpenModal] = useState(isGeneral)

  const close = () => {
    setOpenModal(false)

    cancelScrollOnClose()
  }

  return (
    <div className={css.root}>
      <div className={css.heading} onClick={() => setOpenModal(true)}>
        <Heading className={css.actualValue} variant="medium" noMargin>
          {intl.formatMessage({ id: "recipe-creation/text:category-heading" })}{" "}
          : {recipe?.categories[0]?.name}
        </Heading>
        <img loading="lazy" src={penIcon} alt="" />
      </div>
      <Modal open={openModal} onClose={close} mode="fixed" overflow="hidden">
        {(modalRef, open) => (
          <div className={css.modalContainer}>
            <div
              className={cn(css.modal, { [css.openedModal]: open })}
              ref={modalRef}
            >
              <CategoryStep onSuccess={close} recipeUuid={recipe?.uuid} />
              <button
                className={css.close}
                onClick={close}
                title={intl.formatMessage({ id: "action:close" })}
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default RecipeUpdateCategory
