import React from "react"

import Avatar from "~/components/Avatar/Avatar"
import { Body } from "~/components/ui"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { User } from "~/models/User"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./AvatarName.module.scss"

interface PropTypes {
  user: User
}

export default function AvatarName({ user }: PropTypes) {
  const isCompact = useIsOnCompact()
  return (
    <div className={css.root}>
      <Avatar user={user} variant={isCompact ? "small" : "medium"} />
      <Body variant="body3" semiBold>
        {user.username}
      </Body>
    </div>
  )
}
