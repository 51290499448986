import React from "react"

import { useIntl } from "react-intl"

import useAutosavePopUp from "../hooks/useAutosavePopUp"
import { Body, Button } from "~/components/ui"
import { navigate } from "~/gatsby/navigate"
import pathFromFullId from "~/routes/pathFromFullId"
import { useDeleteUserRecipeMutation } from "~/state/api/communityRecipes"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./RecipeDelete.module.scss"

interface PropTypes {
  uuid: string
}
const RecipeDelete = ({ uuid }: PropTypes) => {
  const intl = useIntl()

  const [deleteRecipe, { isLoading, isError, error, isSuccess }] =
    useDeleteUserRecipeMutation()

  const onSuccess = () => {
    navigate(pathFromFullId(`locale/profile`, intl))
  }

  useAutosavePopUp({
    isSuccess: isSuccess,
    isLoading: isLoading,
    isError: isError,
    error: error,
    onSuccess,
  })

  const handleDelete = async () => {
    await deleteRecipe({
      uuid: uuid,
    })
  }
  return (
    <div className={css.root}>
      <Button
        variant="tertiary"
        onClick={() => handleDelete()}
        color="original"
      >
        Supprimer ma recette
      </Button>
      {isLoading && <Body variant="body1">Loading...</Body>}
      {isError && error && "message" in error && (
        <div className={css.error}>
          <Body variant="body1" color="original">
            {error.message}
          </Body>
        </div>
      )}
    </div>
  )
}

export default RecipeDelete
