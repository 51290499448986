import React, { useContext, useState } from "react"

import { useIntl } from "react-intl"

import useAutosavePopUp from "../hooks/useAutosavePopUp"
import { RecipeContext } from "../RecipeContext"
import binIcon from "./assets/bin.svg"
import SelectKitchenwares from "./SelectKitchenwares/SelectKitchenwares"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import { Body, Button, Heading } from "~/components/ui"
import { useDeleteRecipeKitchenwareMutation } from "~/state/api/communityRecipe/communityRecipeKitchenwares"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./RecipeUpdateKitchenwares.module.scss"

const RecipeUpdateKitchenwares = () => {
  const intl = useIntl()
  const [editMode, setEditMode] = useState(false)

  const recipeContext = useContext(RecipeContext)
  const [
    deleteRecipeKitchenware,
    {
      isLoading: deleteIsLoading,
      isError: deleteIsError,
      isSuccess: deleteIsSuccess,
      error: deleteError,
    },
  ] = useDeleteRecipeKitchenwareMutation()

  useAutosavePopUp({
    isSuccess: deleteIsSuccess,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
    error: deleteError,
  })

  const handleClick = () => {
    setEditMode(!editMode)
  }

  const handleDelete = (uuid: string) => {
    deleteRecipeKitchenware({
      uuid: uuid,
    })
  }

  return (
    <div className={css.root}>
      <div className={css.row}>
        <Heading variant="medium">
          {intl.formatMessage({
            id: "recipe-creation/text:kitchenwares-heading",
          })}
        </Heading>
        <Body variant="body2" color="gray2">
          {intl.formatMessage({
            id: "recipe-creation/text:optional-field",
          })}
        </Body>
      </div>
      {recipeContext?.recipe.kitchenwares_v2 &&
        recipeContext.recipe.kitchenwares_v2.length > 0 && (
          <div>
            {recipeContext.recipe.kitchenwares_v2.map((kitchenware, index) => {
              const kitch = kitchenware.kitchenware
              const quantity = kitchenware.quantity
              return (
                <div key={index} className={css.kitchenware}>
                  <Body variant="body1">
                    {quantity}{" "}
                    {quantity > 1
                      ? kitch.plural_name ?? kitch.name
                      : kitch.name}
                  </Body>

                  <ColorableSVG
                    className={css.delete}
                    color="var(--gray2-color)"
                    href={binIcon}
                    height={22}
                    width={20}
                    onClick={() => handleDelete(kitchenware.uuid)}
                  />
                </div>
              )
            })}
          </div>
        )}
      {!editMode && (
        <Button
          variant="tertiary"
          color="original"
          onClick={handleClick}
          uppercase={false}
          className={css.cta}
        >
          {intl.formatMessage({
            id: "recipe-creation/action:kitchenwares-add",
          })}
        </Button>
      )}
      {editMode && recipeContext && (
        <SelectKitchenwares
          toggleEditMode={handleClick}
          uuid={recipeContext.recipe.uuid}
        />
      )}
    </div>
  )
}

export default RecipeUpdateKitchenwares
