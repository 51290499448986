import React, { useMemo, useState } from "react"

import { useIntl } from "react-intl"

import AutocompleteSearch from "../../../Algolia/AutocompleteSearch/AutocompleteSearch"
import { PartialHit } from "../../../Algolia/CustomSelectionHits/CustomSelectionHits"
import Quantity from "../../../Algolia/Quantity/Quantity"
import SelectedIngredientsList from "./SelectedIngredientsList/SelectedIngredientsList"
import { PM_ID } from "./utils/utils"
import { Body, Button } from "~/components/ui"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./SelectIngredients.module.scss"

export type UnitAlgolia = {
  objectID: string
  plural_name: string
  name: string
  language: string
}

export type CommunityFullIngredientRecipeContent = {
  ingredient: {
    uuid: string
    name: string
  }
  unit: {
    uuid: string
    name: string
  }
  quantity: number | null
  recipe_step: string
  uuid?: string
}

const GATSBY_ALGOLIA_INGREDIENTS_INDEX =
  process.env.GATSBY_ALGOLIA_INGREDIENTS_INDEX ?? ""

const GATSBY_ALGOLIA_UNITS_INDEX = process.env.GATSBY_ALGOLIA_UNITS_INDEX ?? ""
const DEFAULT_UNIT = {
  //TODO : Remove it when the backend will do it automatically
  uuid: "a5feae0a-5c9c-438c-8387-ae855a4008d3",
  name: "pièce",
}
interface Props {
  selectedIngredientsContent: CommunityFullIngredientRecipeContent[] | null
  setSelectedIngredientsContent: (
    ingredients: CommunityFullIngredientRecipeContent[] | null
  ) => void
  selectedIngredientsToDelete: string[] | undefined
  setSelectedIngredientsToDelete: (ingredients: string[]) => void
}

const SelectIngredients = ({
  selectedIngredientsContent,
  setSelectedIngredientsContent,
  selectedIngredientsToDelete,
  setSelectedIngredientsToDelete,
}: Props) => {
  const intl = useIntl()

  const [selectedIngredient, setSelectedIngredient] = useState<PartialHit>()

  const [selectedUnit, setSelectedUnit] = useState<PartialHit>()
  const pmIsSelected = selectedUnit?.objectID === PM_ID

  const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null)
  const allSelected = useMemo(() => {
    if (selectedIngredient && selectedQuantity) {
      return true
    }
    return false
  }, [selectedIngredient, selectedQuantity])

  const unselectAll = () => {
    setSelectedIngredient(undefined)
    setSelectedUnit(undefined)
    setSelectedQuantity(null)
  }

  const unselectUnit = () => {
    setSelectedUnit(undefined)
  }

  const addIngredient = () => {
    //TODO : maybe use a reducer
    if (selectedIngredient) {
      if (selectedIngredientsContent) {
        setSelectedIngredientsContent([
          ...selectedIngredientsContent,
          {
            ingredient: {
              uuid: selectedIngredient.objectID,
              name: selectedIngredient.name,
            },
            unit: {
              uuid: selectedUnit?.objectID ?? DEFAULT_UNIT.uuid,
              name: selectedUnit?.name ?? DEFAULT_UNIT.name,
            },
            quantity: selectedQuantity,
            recipe_step: "",
          },
        ])
      } else {
        setSelectedIngredientsContent([
          {
            ingredient: {
              uuid: selectedIngredient.objectID,
              name: selectedIngredient.name,
            },
            unit: {
              uuid: selectedUnit?.objectID ?? DEFAULT_UNIT.uuid,
              name: selectedUnit?.name ?? DEFAULT_UNIT.name,
            },
            quantity: selectedQuantity,
            recipe_step: "",
          },
        ])
      }
      unselectAll()
    }
  }

  return (
    <div className={css.root}>
      <SelectedIngredientsList
        selectedIngredientsContent={selectedIngredientsContent}
        setSelectIngredientsContent={setSelectedIngredientsContent}
        selectedIngredientsToDelete={selectedIngredientsToDelete}
        setSelectedIngredientsToDelete={setSelectedIngredientsToDelete}
      />
      <AutocompleteSearch
        setSelection={setSelectedIngredient}
        selection={selectedIngredient}
        onBlur={unselectAll}
        index={GATSBY_ALGOLIA_INGREDIENTS_INDEX}
        placeholder={intl.formatMessage({
          id: "recipe-creation/text:creation-step-ingredient-input-placeholder",
        })}
      />
      {selectedIngredient && (
        <>
          <div className={css.row}>
            <AutocompleteSearch
              setSelection={setSelectedUnit}
              selection={selectedUnit}
              index={GATSBY_ALGOLIA_UNITS_INDEX}
              onBlur={unselectUnit}
              placeholder={intl.formatMessage({
                id: "recipe-creation/text:creation-step-input-unity-placeholder",
              })}
            />
            {!pmIsSelected && <Quantity setQuantity={setSelectedQuantity} />}
          </div>
          <div className={css.cta}>
            <Body
              variant="body1"
              color="gray2"
              onClick={unselectAll}
              className={css.cancel}
              semiBold
            >
              {intl.formatMessage({
                id: "form/action:cancel",
              })}
            </Body>
            <Button
              variant="secondary"
              size="small"
              color={"original"}
              className={css.validate}
              onClick={addIngredient}
              type="button"
              disabled={!allSelected && !pmIsSelected}
            >
              {intl.formatMessage({
                id: "form/action:submit",
              })}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default SelectIngredients
