import React, { ChangeEvent, CSSProperties } from "react"

import cn from "classnames"

import { Body } from "~/components/ui"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./SliderRange.module.scss"

interface PropTypes {
  value: number
  setValue: (value: number) => void
}

const STEP1 = { max: 10 }
const STEP2 = { max: 56, step: 5 }
const STEP3 = { step: 60 }

const inputValueToValueWithStep = (value: number) => {
  let valueWithStep = 0
  if (value > 0) {
    valueWithStep += value < STEP1.max ? value : STEP1.max
  }
  if (value > STEP1.max) {
    valueWithStep +=
      value < STEP2.max
        ? (value - STEP1.max) * STEP2.step
        : (STEP2.max - STEP1.max) * STEP2.step
  }
  if (value > STEP2.max) {
    valueWithStep += (value - STEP2.max) * STEP3.step
  }
  return valueWithStep
}

const valueWithStepToInputValue = (value: number) => {
  let valueWithStep = 0
  if (value > 0) {
    valueWithStep += value < STEP1.max ? value : STEP1.max
  }
  if (value > STEP1.max) {
    valueWithStep +=
      value < STEP1.max + STEP2.max * STEP2.step
        ? (value - STEP1.max) / STEP2.step
        : ((STEP2.max - STEP1.max) * STEP2.step) / STEP2.step
  }
  if (value > STEP1.max + STEP2.max * STEP2.step) {
    valueWithStep += (value - (STEP1.max + STEP2.max * STEP2.step)) / STEP3.step
  }
  return valueWithStep
}

function SliderRange({ value, setValue }: PropTypes) {
  const handleChangeTime = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)
    setValue(Math.round(inputValueToValueWithStep(value)))
  }

  const hours = Math.round(value / 60)
  const minutes = Math.round(value % 60)

  return (
    <>
      <div
        className={cn(css.root)}
        style={
          {
            "--progress-bar-percent": `${valueWithStepToInputValue(value)}%`,
          } as CSSProperties
        }
      >
        <Body
          className={css.value}
          style={
            {
              "--progress-bar-percent": `${valueWithStepToInputValue(value)}%`,
            } as CSSProperties
          }
          variant="body3"
          semiBold
        >
          {value / 60 >= 1 && `${hours}h`}
          {value % 60 >= 1 && minutes >= 10
            ? `${minutes}min`
            : `0${minutes}min`}
        </Body>
        <input
          onChange={handleChangeTime}
          type="range"
          min="0"
          max="100"
          className={css.progressBar}
          value={valueWithStepToInputValue(value)}
        />
      </div>
    </>
  )
}

export default SliderRange
