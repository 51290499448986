import React from "react"

import { useIntl } from "react-intl"

import { RecipeContext } from "../RecipeContext"
import ErrorMessage from "~/components/ErrorMessage/ErrorMessage"
import { Body } from "~/components/ui"
import { capitalizeFirstLetter } from "~/utils/string"

import css from "./RecipeStatus.module.scss"

const RecipeStatus = () => {
  const intl = useIntl()
  const recipeContext = React.useContext(RecipeContext)

  const formattedStatus =
    recipeContext?.recipe.status !== "validated"
      ? recipeContext?.recipe.is_draft
        ? "private"
        : recipeContext?.recipe.status ?? "created"
      : "validated"

  return (
    <div className={css.root}>
      <div className={css.row}>
        <Body variant="body2" semiBold>
          {intl.formatMessage({
            id: "recipe-creation/text:recipe-status",
          })}
        </Body>
        <div className={css.status}>
          <Body variant="body4" semiBold color="gray1" className={css.text}>
            {capitalizeFirstLetter(
              intl.formatMessage({
                id: `recipe-creation/status:${formattedStatus}`,
              })
            )}
          </Body>
        </div>
      </div>
      {formattedStatus === "refused" &&
        recipeContext?.recipe.reason_for_refusal && (
          <div className={css.refusal}>
            <ErrorMessage error={recipeContext?.recipe.reason_for_refusal} />
          </div>
        )}
    </div>
  )
}

export default RecipeStatus
