import React from "react"

import { useIntl } from "react-intl"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./Quantity.module.scss"

interface PropTypes {
  setQuantity: (quantity: number) => void
  defaultValue?: number
}
const Quantity = ({ setQuantity, defaultValue, ...rest }: PropTypes) => {
  const intl = useIntl()
  return (
    <div className={css.root}>
      <input
        type="number"
        min="1"
        defaultValue={defaultValue}
        onChange={event => setQuantity(parseInt(event.currentTarget.value))}
        className={css.input}
        placeholder={intl.formatMessage({
          id: "recipe-creation/text:input-quantity-placeholder",
        })}
      />
    </div>
  )
}

export default Quantity
