import React from "react"

import { CommunityFullIngredientRecipeContent } from "../SelectIngredients"
import binIcon from "./assets/bin.svg"
import penIcon from "./assets/pen.svg"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import { Body } from "~/components/ui"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./SelectedIngredientsList.module.scss"

interface PropTypes {
  selectedIngredientsContent: CommunityFullIngredientRecipeContent[] | null
  setSelectIngredientsContent: (
    ingredients: CommunityFullIngredientRecipeContent[] | null
  ) => void
  selectedIngredientsToDelete: string[] | undefined
  setSelectedIngredientsToDelete: (ingredients: string[]) => void
}
const SelectedIngredientsList = ({
  selectedIngredientsContent,
  setSelectIngredientsContent,
  selectedIngredientsToDelete,
  setSelectedIngredientsToDelete,
  ...rest
}: PropTypes) => {
  return (
    <div className={css.root}>
      {selectedIngredientsContent?.map((ingredient, index) => (
        <div
          className={css.ingredient}
          key={index}
          style={
            index === selectedIngredientsContent.length - 1
              ? { marginBottom: "8px" }
              : {}
          }
        >
          <Body variant="body1">
            {ingredient.quantity} {ingredient.unit.name}{" "}
            {ingredient.ingredient.name}{" "}
          </Body>
          <div className={css.icons}>
            <ColorableSVG
              className={css.icon}
              color="var(--gray2-color)"
              href={binIcon}
              height={22}
              width={20}
              onClick={() => {
                const newIngredients = selectedIngredientsContent?.filter(
                  (ingredient, idx) => idx !== index
                )
                setSelectIngredientsContent(newIngredients)
                if (ingredient.uuid) {
                  const newIngredientsToDelete =
                    selectedIngredientsToDelete ?? []
                  newIngredientsToDelete?.push(ingredient.uuid)
                  setSelectedIngredientsToDelete(newIngredientsToDelete)
                }
              }}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default SelectedIngredientsList
