import React, { useEffect, useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import cn from "classnames"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import * as yup from "yup"

import errorIcon from "../assets/error.svg"
import useAutosavePopUp from "../hooks/useAutosavePopUp"
import { Body, Fieldset, TextAreaInput } from "~/components/ui"
import { useUpdateUserRecipeMutation } from "~/state/api/communityRecipes"
import { ErrorResponse, ErrorResponseDjango } from "~/types/global-types"
import { getErrorMessage } from "~/utils/errors"
import getFormValidators from "~/utils/formValidation"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./RecipeUpdateTextAreaField.module.scss"

type EditableTextFormProps = {
  [objectKey: string]: string
}

type RecipeUpdateTextAreaFieldProps = {
  initialValue: EditableTextFormProps
  placeholder: string
  apiKey: "description"
  defaultValue?: string
  required?: boolean
  recipeUuid: string
}

export function RecipeUpdateTextAreaField({
  initialValue,
  placeholder,
  apiKey,
  defaultValue,
  required = true,
  recipeUuid,
}: RecipeUpdateTextAreaFieldProps) {
  const intl = useIntl()

  const [value, setValue] = useState<EditableTextFormProps>(initialValue)
  const [isChange, setIsChange] = useState(false)

  const { requiredString } = getFormValidators(intl)
  const [updateData, { isLoading, isError, isSuccess, error, data }] =
    useUpdateUserRecipeMutation()

  const validationSchema = useMemo(
    () =>
      yup.object().shape(
        required
          ? {
              [apiKey]: requiredString,
            }
          : {}
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { register, handleSubmit, getValues, ...form } =
    useForm<EditableTextFormProps>({
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
      defaultValues:
        value[apiKey] === "" && defaultValue
          ? {
              [apiKey]: defaultValue,
            }
          : value,
    })

  useAutosavePopUp({
    isSuccess,
    isLoading,
    isError,
    error,
    onSuccess: () => {
      if (data) {
        const newValue = { ...value }
        newValue[apiKey] = data[apiKey]
        data && setValue(newValue)
      }
    },
  })

  const submit = async (values: EditableTextFormProps) => {
    if (initialValue[apiKey] !== values[apiKey]) {
      await updateData({
        uuid: recipeUuid,
        body: {
          [apiKey]: values[apiKey],
        },
      })
    }
  }

  const { errors, isSubmitted } = form.formState

  return (
    <div>
      <div className={css.root}>
        <form
          onBlur={handleSubmit(submit)}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Fieldset error={errors[apiKey]?.message}>
            <>
              <TextAreaInput
                rows={3}
                {...register(apiKey)}
                placeholder={placeholder}
                errors={errors[apiKey]}
                onChange={() => {
                  setIsChange(true)
                }}
                className={cn(css.inputText, {
                  [css.inputError]: isError && isSubmitted && !isChange,
                })}
              />
            </>
          </Fieldset>
          {/* This error message is displayed in english */}
          {isError && isSubmitted && !isChange && (
            <div className={css.messageWrap}>
              <img loading="lazy" src={errorIcon} alt="" />
              <Body variant="body3" color="original" noMargin>
                {getErrorMessage(
                  (
                    error as unknown as {
                      data?: ErrorResponse | ErrorResponseDjango
                    }
                  )?.data
                ) ?? "Erreur"}
              </Body>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
